import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";

import Layout from "src/components/layout";
import SEO from "src/components/seo";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { order: DESC, fields: frontmatter___date }, filter: { frontmatter: { draft: { eq: false } } }) {
        edges {
          node {
            frontmatter {
              title
              slug
              date(formatString: "MMMM DD, YYYY")
              thumbnail {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Silvercast"
        description="A technology conqueror, a life surfer, a thinker. I'm totally passionate about creating value for others and that, in my mind, doesn't have to limit to our daily jobs."
      />
      <div className="home-banner flex flex-col justify-center lg:flex-row">
        <div className="flex-grow">
          <div className="text-5xl text-ui-1 font-extrabold">Technology</div>
          <div className="text-5xl text-ui-1 font-extrabold mt-4">Passion</div>
          <div className="flex items-end mt-4">
            <div className="text-2xl text-ui-5 font-light italic mr-2">and</div>
            <div className="text-5xl text-ui-1 font-extrabold">Life</div>
          </div>
        </div>
        <div className="flex-shrink">
          <StaticImage src="../content/teamwork.svg" alt="Silvercast" placeholder="blurred" />
        </div>
      </div>
      <div className="mt-12">
        {data.allMdx.edges.map(i => (
          <Link to={i.node.frontmatter.slug} key={i.node.frontmatter.slug}>
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-2/5 md:flex-shrink-0">
                <GatsbyImage
                  image={i.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                  alt={`Silvercast | ${i.node.frontmatter.title}`}
                  objectFit="contain"
                />
              </div>
              <div className="md:ml-4">
                <div className="text-xl font-bold text-ui-1">{i.node.frontmatter.title}</div>
                <div className="text-base text-ui-3 mt-2">{i.node.excerpt}...</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  );
};

export default IndexPage;
